import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

type Props = {}

const UserImage = (props: Props) => {
    return (
        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Profile' />
    )
}

export default UserImage